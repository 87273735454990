import React from "react"
import "./styles/global.scss"
import LogoIcon from "./images/logo.png"
import Header from "./parts/header.js"
import UserPage from "./parts/userPage.js"
import CommunitiesList from "./parts/communitiesList.js"
import TheFeed from "./parts/thefeed.js"
import Login from "./parts/login.js"
import Logout from "./parts/logout.js"
import TheNotifications from "./parts/theNotifications.js"
import Recaptcha from "react-google-recaptcha"
import axios from "axios"
import ErrorMessage from "./parts/errormessage.js"
import SuccessMessage from "./parts/successmessage.js"

import { BrowserRouter, Routes, Route } from "react-router-dom"

class App extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            isAuthenticated: false,
            reCapToken: null,
            emailID: "",
            errorCode: null,
            authToken: null,
            formResponse: null,
        }
    }

    recapChange = (value) => {
        // console.log("Captcha value:", value);
        this.setState({ reCapToken: value })
        // console.log(this.state);
    }

    onChangeHandler = (event) => {
        let nam = event.target.name
        let val = event.target.value
        this.setState({ [nam]: val })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        // console.log('form submit');
        // console.log(this.state);
        const formData = this.state

        axios({
            method: "post",
            url: "https://octobertiger.auth0.com/oauth/token",
            headers: {
                "content-type": "application/json",
            },
            data: {
                audience: "http://localhost:3000/",
                crossDomain: "true",
                grant_type: "client_credentials",
                client_id: process.env.REACT_APP_AUTH_CLIENT_KEY,
                client_secret: process.env.REACT_APP_AUTH_SECRET_KEY,
            },
        })
            // .then(function (response) {
            .then((response) => {
                //response for auth token
                console.log(response)
                this.setState({ authToken: response })
                axios({
                    method: "post",
                    url: "https://api.asteroid.social/newsletter/",
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + response.data.access_token,
                    },
                    data: {
                        response: formData.reCapToken,
                        email: formData.emailID,
                    },
                })
                    // .then(function(response){
                    .then((response) => {
                        //response for form data
                        this.setState({ formResponse: true })
                        console.log(response)
                    })
                    .catch((error) => {
                        //error for form data
                        this.setState({ errorCode: error.response.status }, () =>
                            console.log(this.state)
                        )
                        this.setState({ formResponse: false })
                        console.log(error)
                        // console.log(error.response.status);
                        // this.forceUpdate();
                        if (error.response) {
                            console.log(error.response.data) // => the response payload
                        }
                    })
            })
            // .catch(function(error){
            .catch((error) => {
                //error for token
                // console.log(error);
                this.setState({ authToken: error })
                this.setState({ formResponse: false })
                this.forceUpdate()
            })
    }

    render() {
        const { isAuthenticated, reCapToken, emailID, formResponse, errorCode } =
            this.state
        const recaptchaRef = React.createRef()
        let FormResponseMessage
        // console.log(this.state);

        if (isAuthenticated == true) {
            return (
                <>
                    <BrowserRouter forceRefresh={false}>
                        <div className="flex-4 flex-contain left-panel">
                            <div className="flex-2"></div>
                            <div className="flex-4">
                                <Header />
                            </div>
                        </div>

                        <div className="flex-5 main-content-area">
                            <Routes>
                                <Route index path="/feed/" element={<TheFeed />} />
                                <Route
                                    path="/notifications/"
                                    element={<TheNotifications />}
                                />
                                <Route path="/user/*" element={<UserPage />} />
                                <Route
                                    path="/communities/*"
                                    element={<CommunitiesList />}
                                />
                            </Routes>
                        </div>
                    </BrowserRouter>
                </>
            )
        } else {
            return (
                <>
                    <div className="hypePageContain">
                        <div className="flex-6 logoContain">
                            <div className="logoArea">
                                <div className="flex-8 logoIcon">
                                    <img src={LogoIcon} />
                                </div>
                                <div className="flex-1 siteTitle">ASTEROID</div>
                                <div className="flex-1 siteDesc">
                                    To boldly go. Social Networking, Media and
                                    Community.{" "}
                                </div>
                                <div className="flex-1 siteStatus">
                                    In Development
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 formContainer">
                            <div className="loginBackdrop flex-contain">
                                <div className="blurb-contain flex-1">
                                    > If you’re interested in learning more or being
                                    included in the alpha/beta builds, drop us an
                                    email to let you know when we’re ready.
                                </div>
                                {formResponse == true ? (
                                    <SuccessMessage />
                                ) : formResponse == false ? (
                                    <ErrorMessage errorDetails={errorCode} />
                                ) : (
                                    ""
                                )}

                                <div className="flex-break"></div>
                                <form
                                    onSubmit={this.handleSubmit}
                                    action="submit"
                                    className="flex-1 hypeform"
                                >
                                    <input
                                        type="email"
                                        name="emailID"
                                        placeholder="Email"
                                        value={this.state.emailID}
                                        required
                                        onChange={this.onChangeHandler}
                                    />
                                    <div className="flex-break"></div>
                                    <Recaptcha
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        onChange={this.recapChange}
                                    />
                                    <button type="submit" value="Submit">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default App
