import GetTheNotifications from "./getTheNotifications.js"
import MainSideBar from "./MainSideBar.js"

function TheNotifications() {
    return (
        <>
            <div className="flex-3 the-feed">
                <GetTheNotifications />
            </div>
            <div className="flex-2">
                <MainSideBar />
            </div>
        </>
    )
}

export default TheNotifications
