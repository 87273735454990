function WhatsHappening() {
    return (
        <>
            <div className="interest">
                <h4 className="flex-4">Things I Post About</h4>
                <div className="wordlist">
                    <span>Fursuits</span>
                    <span>4X4</span>
                    <span>Photography</span>
                    <span>Gaming</span>
                    <span>Politics</span>
                    <span>Music</span>
                    <span>FursuitFriday</span>
                </div>
            </div>
        </>
    )
}

export default WhatsHappening
