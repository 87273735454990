import UserPosts from "./userPosts.js"
import UserMedia from "./userMedia.js"
import UserJournals from "./userJournals.js"
import UserReplies from "./userReplies.js"
import UserLikes from "./userLikes.js"
import Profile from "./profile.js"
import Feednav from "./feed-nav.js"

import { Routes, Route } from "react-router-dom"

function theUser() {
    return (
        <>
            <div className="flex-3 the-feed">
                <Feednav />
                <Routes>
                    <Route path="/">
                        <Route index path="/posts/" element={<UserPosts />} />
                        <Route path="/media/" element={<UserMedia />} />
                        <Route path="/journals/" element={<UserJournals />} />
                        <Route path="/replies/" element={<UserReplies />} />
                        <Route path="/likes/" element={<UserLikes />} />
                    </Route>
                </Routes>
            </div>
            <div className="flex-2">
                <Profile />
            </div>
        </>
    )
}

export default theUser
