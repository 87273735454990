import React from "react"
// import ReactDOM from "react-dom"

class WhatsHappening extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            isActive: false,
        }
        //onclick events can go here
    }

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive })
    }

    render() {
        const { error, isLoaded, isActive } = this.state
        console.log(this.state)

        return (
            <>
                <div className={`flex-3 whatsHappening ${isActive ? "active" : ""}`}>
                    <h4 className="flex-4" onClick={this.handleToggle}>
                        What's Happening
                    </h4>
                    <h5 className="flex-4">
                        Headlines and news from around the globe
                    </h5>
                    <div className="newsItemList">
                        <div className="newsItem">
                            <div className="context">Trending</div>
                            <div className="headLine">
                                Twitter killer is upon us and it looks damn good.{" "}
                            </div>
                            <div className="postCount">
                                <span>438k</span> Posts
                            </div>
                        </div>
                        <div className="newsItem">
                            <div className="context">Trending</div>
                            <div className="headLine">
                                Twitter killer is upon us and it looks damn good.{" "}
                            </div>
                            <div className="postCount">
                                <span>438k</span> Posts
                            </div>
                        </div>
                        <div className="newsItem">
                            <div className="context">Trending</div>
                            <div className="headLine">
                                Twitter killer is upon us and it looks damn good.{" "}
                            </div>
                            <div className="postCount">
                                <span>438k</span> Posts
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default WhatsHappening
