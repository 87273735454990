import GetCommunitites from "./getCommunities.js"
import Profile from "./profile.js"
// import { Routes, Route } from "react-router-dom"

function communities() {
    return (
        <>
            <div className="flex-3 communityList">
                <GetCommunitites />
            </div>
            <div className="flex-2">
                <Profile />
            </div>
        </>
    )
}

export default communities
