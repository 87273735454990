function errorMessage(stuff) {
    return (
        <div className="blurb-contain error-bg flex-1">
            {stuff.errorDetails == 409
                ? "You are already signed up!"
                : "There was an error submitting your request, please try again."}
        </div>
    )
}

export default errorMessage

