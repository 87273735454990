import GetTheFeed from "./getTheFeed.js"
import MainSideBar from "./MainSideBar.js"

// import { Routes, Route } from "react-router-dom"

function theFeed() {
    return (
        <>
            <div className="flex-3 the-feed">
                <GetTheFeed />
            </div>
            <div className="flex-2">
                <MainSideBar />
            </div>
        </>
    )
}

export default theFeed
