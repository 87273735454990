import React from "react"
// import ReactDOM from "react-dom"
/*Images*/
import { ReactComponent as ChattingIcon } from "../images/chatting.svg"
// import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
// import { ReactComponent as UsersIcon } from "../images/users.svg"
// import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
// import { ReactComponent as StarIcon } from "../images/star.svg"
import { ReactComponent as UploadIcon } from "../images/upload.svg"
// import { ReactComponent as LinkIcon } from "../images/link.svg"
// import { ReactComponent as LocationIcon } from "../images/location.svg"
import { ReactComponent as FavouriteIcon } from "../images/Favourite.svg"
import { ReactComponent as ExchangeIcon } from "../images/exchange.svg"
import { ReactComponent as BookSearch } from "../images/book-search.svg"
import { ReactComponent as OptionsIcon } from "../images/dots.svg"
import { ReactComponent as MediaUploadIcon } from "../images/image-rectangle.svg"
// import { ReactComponent as CloseIcon } from "../images/close.svg"
import tg1 from "../images/tg1.png"
import tg2 from "../images/tg2.png"
import tg3 from "../images/tg3.png"
import tg4 from "../images/tg4.png"

class Post extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            postOptions: false,
            optionSelected: null,
            postID: null,
            replyToPost: null,
        }
        //onclick events can go here
    }

    options = (postID) => {
        this.setState({
            postOptions: true,
            postID: postID,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    optionSelected = (optionSelected) => {
        this.setState({
            postOptions: false,
            optionSelection: optionSelected,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        const {
            error,
            isLoaded,
            postOptions,
            optionSelection,
            postID,
            replyToPost,
            visibility,
            visibilitySelection,
        } = this.state
        // console.log(this.state);
        return (
            <>
                <article className="post" data-post-id="32904823094590475034854">
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                        </div>
                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                    <div
                        className="postOptions"
                        onClick={() => this.options("32904823094590475034854")}
                    >
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "32904823094590475034854" && postOptions
                                ? "active"
                                : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>
                    <div className="interact-bar">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                </article>
                <article className="post repost">
                    <div className="repost-status">
                        <ExchangeIcon />
                        <span>@Burrbles</span>
                    </div>
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                        </div>
                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                    <div className="postOptions" onClick={() => this.options()}>
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "7777" && postOptions ? "active" : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>
                    <div className="mini-post flex-4 ">
                        <article className="post">
                            <div className="avatar flex-1">
                                <span className="avatar-img"></span>
                            </div>
                            <div className="content-area flex-2">
                                <div className="details">
                                    <div className="username flex-0">@Burrbles</div>
                                    <div className="dateofpost flex-0">
                                        on April 11th at 12:30pm
                                    </div>
                                </div>
                                <div className="the-content">
                                    My first post on the new platform! So excited to
                                    start this new journey!Hello world! What do we
                                    think about this? What would you change any
                                    feature requests? Be mindful it’s a small team
                                    building this!
                                </div>
                            </div>
                            <div
                                className="postOptions"
                                onClick={() => this.options()}
                            >
                                <OptionsIcon />
                            </div>
                            <div
                                className={`optionsMenu subMenu ${
                                    postID === "7777" && postOptions ? "active" : ""
                                }`}
                            >
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Follow")}
                                >
                                    Follow <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Mute")}
                                >
                                    Mute <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Block")}
                                >
                                    Block <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Report")}
                                >
                                    Report Post
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="interact-bar ">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                </article>
                <article className="post repost">
                    <div className="repost-status">
                        <ExchangeIcon />
                        <span>@Burrbles</span>
                    </div>
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                        </div>
                        <div className="the-content">
                            <img className="flex-1" src={tg1} />
                            <img className="flex-1" src={tg2} />
                            <img className="flex-1" src={tg3} />
                            <img className="flex-1" src={tg4} />
                        </div>
                    </div>
                    <div className="postOptions" onClick={() => this.options()}>
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "7777" && postOptions ? "active" : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>
                    <div className="interact-bar ">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                </article>
                <article className="post">
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                        </div>
                        <div className="the-content">
                            <h3>What is responsive web design?</h3>
                            <p>
                                In 2021, there were 4.32 billion mobile internet
                                users globally–and that number is only growing. On
                                top of that, mobile accounts for more than half of
                                all web traffic, with an even larger share in
                                mobile-first markets such as Asia and Africa.
                            </p>
                            <div className="continue-reading">
                                <BookSearch />
                                <span className="booksearch">Continue Reading</span>
                            </div>
                        </div>
                    </div>
                    <div className="postOptions" onClick={() => this.options()}>
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "7777" && postOptions ? "active" : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>

                    <div className="interact-bar">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                </article>
                <article className="post withreplies">
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                        </div>
                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                    <div className="postOptions" onClick={() => this.options()}>
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "7777" && postOptions ? "active" : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>
                    <div className="interact-bar">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                    <div className={`replyBox ${replyToPost ? "active" : ""}`}>
                        <div className="avatar flex-1">
                            <span className="avatar-img"></span>
                        </div>
                        <div
                            type="text"
                            className="thePostContent flex-4"
                            contentEditable="true"
                            data-text="Type your reply here."
                        ></div>
                        <div className="mediaUpload flex-4">
                            <MediaUploadIcon />
                        </div>
                        <div className="postButton flex-1">Reply</div>
                    </div>
                    <div className="reply">
                        <article className="replyPost">
                            <div className="avatar flex-1">
                                <span className="avatar-img"></span>
                            </div>
                            <div className="content-area flex-2">
                                <div className="details">
                                    <div className="username flex-0">@Burrbles</div>
                                    <div className="dateofpost flex-0">
                                        on April 11th at 12:30pm
                                    </div>
                                </div>
                                <div className="the-content">
                                    My first post on the new platform! So excited to
                                    start this new journey!Hello world! What do we
                                    think about this? What would you change any
                                    feature requests? Be mindful it’s a small team
                                    building this!
                                </div>
                            </div>
                            <div
                                className="postOptions"
                                onClick={() => this.options()}
                            >
                                <OptionsIcon />
                            </div>
                            <div
                                className={`optionsMenu subMenu ${
                                    postID === "7777" && postOptions ? "active" : ""
                                }`}
                            >
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Follow")}
                                >
                                    Follow <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Mute")}
                                >
                                    Mute <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Block")}
                                >
                                    Block <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Report")}
                                >
                                    Report Post
                                </div>
                            </div>
                            <div className="interact-bar">
                                <div className="spacer flex-1"></div>
                                <div className="flex-2">
                                    <div className="bar-container comments">
                                        <ChattingIcon />
                                        <span className="count">200</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container repost">
                                        <ExchangeIcon />
                                        <span className="count">1.5k</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container likes">
                                        <FavouriteIcon />
                                        <span className="count">356</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container share">
                                        <UploadIcon />
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="reply">
                        <article className="replyPost">
                            <div className="avatar flex-1">
                                <span className="avatar-img"></span>
                            </div>
                            <div className="content-area flex-2">
                                <div className="details">
                                    <div className="username flex-0">@Burrbles</div>
                                    <div className="dateofpost flex-0">
                                        on April 11th at 12:30pm
                                    </div>
                                </div>
                                <div className="the-content">
                                    My first post on the new platform! So excited to
                                    start this new journey!Hello world! What do we
                                    think about this? What would you change any
                                    feature requests? Be mindful it’s a small team
                                    building this!
                                </div>
                            </div>
                            <div
                                className="postOptions"
                                onClick={() => this.options()}
                            >
                                <OptionsIcon />
                            </div>
                            <div
                                className={`optionsMenu subMenu ${
                                    postID === "7777" && postOptions ? "active" : ""
                                }`}
                            >
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Follow")}
                                >
                                    Follow <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Mute")}
                                >
                                    Mute <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Block")}
                                >
                                    Block <span className="username">@User</span>{" "}
                                </div>
                                <div
                                    className="postOption"
                                    onClick={() => this.optionSelected("Report")}
                                >
                                    Report Post
                                </div>
                            </div>
                            <div className="interact-bar">
                                <div className="spacer flex-1"></div>
                                <div className="flex-2">
                                    <div className="bar-container comments">
                                        <ChattingIcon />
                                        <span className="count">200</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container repost">
                                        <ExchangeIcon />
                                        <span className="count">1.5k</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container likes">
                                        <FavouriteIcon />
                                        <span className="count">356</span>
                                    </div>
                                </div>
                                <div className="flex-2">
                                    <div className="bar-container share">
                                        <UploadIcon />
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </article>
            </>
        )
    }
}

export default Post
