import React from "react"
// import ReactDOM from "react-dom"
import { NavLink } from "react-router-dom"

class FeedNav extends React.Component {
    constructor(props) {
        super(props)

        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            pageID: window.location.pathname,
        }
        //onclick events can go here
    }

    setPageID = () => {
        this.setState({
            pageID: window.location.pathname,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        const { pageID } = this.state
        console.log(this.state)
        return (
            <div className="feednav">
                <ul>
                    <li
                        className={`profileNavItem ${
                            pageID === "/user/posts" || pageID === "/user"
                                ? "selected"
                                : ""
                        }`}
                    >
                        <NavLink to="/user/posts"> Posts </NavLink>
                    </li>
                    <li
                        className={`profileNavItem ${
                            pageID === "/user/journals" ? "selected" : ""
                        }`}
                    >
                        <NavLink to="/user/journals"> Journals </NavLink>
                    </li>
                    <li
                        className={`profileNavItem ${
                            pageID === "/user/replies" ? "selected" : ""
                        }`}
                    >
                        <NavLink to="/user/replies"> Replies </NavLink>
                    </li>
                    <li
                        className={`profileNavItem ${
                            pageID === "/user/media" ? "selected" : ""
                        }`}
                    >
                        <NavLink to="/user/media"> Media </NavLink>
                    </li>
                    <li
                        className={`profileNavItem ${
                            pageID === "/user/likes" ? "selected" : ""
                        }`}
                    >
                        <NavLink to="/user/likes"> Likes </NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}

export default FeedNav
