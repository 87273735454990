import React from "react"
// import ReactDOM from "react-dom"
import SearchBar from "./searchBar.js"
import WhatsHappening from "./whatsHappening.js"
import MyInterests from "./myInterests.js"

class MainSideBar extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            followStatus: "Follow",
        }
        //onclick events can go here
    }

    follow = (followStatus) => {
        if (followStatus === "Follow") {
            this.setState({
                followStatus: "Following",
            })
        } else {
            this.setState({
                followStatus: "Follow",
            })
        }

        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        // const { error, isLoaded, followStatus } = this.state
        console.log(this.state)

        return (
            <>
                <SearchBar />

                <div className="MainSideBar">
                    <WhatsHappening />
                    <MyInterests />
                    <div className="MyCommunities"></div>
                </div>
            </>
        )
    }
}

export default MainSideBar
