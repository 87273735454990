import React from "react"
// import ReactDOM from "react-dom"
/*Images*/
import { ReactComponent as ChattingIcon } from "../images/chatting.svg"
// import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
// import { ReactComponent as UsersIcon } from "../images/users.svg"
// import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
// import { ReactComponent as StarIcon } from "../images/star.svg"
import { ReactComponent as UploadIcon } from "../images/upload.svg"
// import { ReactComponent as LinkIcon } from "../images/link.svg"
// import { ReactComponent as LocationIcon } from "../images/location.svg"
import { ReactComponent as FavouriteIcon } from "../images/Favourite.svg"
import { ReactComponent as ExchangeIcon } from "../images/exchange.svg"
// import { ReactComponent as BookSearch } from "../images/book-search.svg"
import { ReactComponent as OptionsIcon } from "../images/dots.svg"
import { ReactComponent as UserIcon } from "../images/user.svg"
// import tg1 from "../images/tg1.png"
// import tg2 from "../images/tg2.png"
// import tg3 from "../images/tg3.png"
// import tg4 from "../images/tg4.png"

class Post extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            postOptions: false,
            optionSelected: null,
            postID: null,
        }
        //onclick events can go here
    }

    options = (postID) => {
        this.setState({
            postOptions: true,
            postID: postID,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    optionSelected = (optionSelected) => {
        this.setState({
            postOptions: false,
            optionSelection: optionSelected,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        const { error, isLoaded, postOptions, optionSelection, postID } = this.state
        console.log(this.state)
        return (
            <>
                <article className="post" data-post-id="32904823094590475034854">
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="username flex-0">@Burrbles</div>
                            <div className="dateofpost flex-0">
                                on April 11th at 12:30pm
                            </div>
                            <div className="replyStatus flex-5">
                                Replying to{" "}
                                <span className="username">@Burrbles</span>
                            </div>
                        </div>
                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                    <div
                        className="postOptions"
                        onClick={() => this.options("32904823094590475034854")}
                    >
                        <OptionsIcon />
                    </div>
                    <div
                        className={`optionsMenu subMenu ${
                            postID === "32904823094590475034854" && postOptions
                                ? "active"
                                : ""
                        }`}
                    >
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Follow")}
                        >
                            Follow <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Mute")}
                        >
                            Mute <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Block")}
                        >
                            Block <span className="username">@User</span>{" "}
                        </div>
                        <div
                            className="postOption"
                            onClick={() => this.optionSelected("Report")}
                        >
                            Report Post
                        </div>
                    </div>
                    <div className="interact-bar">
                        <div className="spacer flex-1"></div>
                        <div className="flex-2">
                            <div className="bar-container comments">
                                <ChattingIcon />
                                <span className="count">200</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container repost">
                                <ExchangeIcon />
                                <span className="count">1.5k</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container likes">
                                <FavouriteIcon />
                                <span className="count">356</span>
                            </div>
                        </div>
                        <div className="flex-2">
                            <div className="bar-container share">
                                <UploadIcon />
                            </div>
                        </div>
                    </div>
                </article>
                <article
                    className="post notificationPost"
                    data-post-id="32904823094590475034854"
                >
                    <div className="postSatus-icon flex-1">
                        <FavouriteIcon />
                    </div>

                    <div className="content-area flex-2">
                        <div className="avatar avatarList flex-1">
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                        </div>
                        <div className="details postDetails">
                            <span className="username">@burrbles</span> and 13 others
                            liked your post.
                        </div>

                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                </article>
                <article
                    className="post notificationPost"
                    data-post-id="32904823094590475034854"
                >
                    <div className="postSatus-icon flex-1">
                        <ExchangeIcon />
                    </div>

                    <div className="content-area flex-2">
                        <div className="avatar avatarList flex-1">
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                        </div>
                        <div className="details postDetails">
                            <span className="username">@burrbles</span> and 13 others
                            reposted your post.
                        </div>

                        <div className="the-content">
                            My first post on the new platform! So excited to start
                            this new journey!Hello world! What do we think about
                            this? What would you change any feature requests? Be
                            mindful it’s a small team building this!
                        </div>
                    </div>
                </article>
                <article
                    className="post notificationPost"
                    data-post-id="32904823094590475034854"
                >
                    <div className="postSatus-icon flex-1">
                        <UserIcon />
                    </div>

                    <div className="content-area flex-2">
                        <div className="avatar avatarList flex-1">
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                            <span className="avatar-img"></span>
                        </div>
                        <div className="details postDetails">
                            <span className="username">@burrbles</span> and 13 others
                            followed you.
                        </div>
                    </div>
                </article>
            </>
        )
    }
}

export default Post
