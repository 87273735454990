import React from "react"
// import ReactDOM from "react-dom"
import { Link } from "react-router-dom"

/*IMAGES*/
import { ReactComponent as FeatherIcon } from "../images/feather.svg"
import { ReactComponent as NotificationIcon } from "../images/notifcation.svg"
import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
import { ReactComponent as UsersIcon } from "../images/users.svg"
import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
import { ReactComponent as AddcircleIcon } from "../images/add-circle.svg"
import { ReactComponent as MediaUploadIcon } from "../images/image-rectangle.svg"
// import { ReactComponent as DownArrowIcon } from "../images/downarrow.svg"
import { ReactComponent as CloseIcon } from "../images/close.svg"
// import { ReactComponent as CheckMarkIcon } from "../images/checkmark.svg"

class Header extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            newPost: false,
            visibility: false,
            visibilitySelection: "Everyone",
        }
        //onclick events can go here
    }

    newPost = () => {
        this.setState({
            newPost: true,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    closePost = () => {
        this.setState({
            newPost: false,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    visibilityOptions = (value) => {
        this.setState({
            visibilitySelection: value,
            visibility: false,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    visibility = () => {
        this.setState({
            visibility: true,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        const { error, isLoaded, newPost, visibility, visibilitySelection } =
            this.state
        console.log(this.state)

        return (
            <>
                <div className={`newPostBox ${newPost ? "active" : ""}`}>
                    <div
                        className={`visibility flex-1 ${visibility ? "active" : ""}`}
                        onClick={() => this.visibility()}
                    >
                        {visibilitySelection}
                    </div>
                    <div
                        className={`visibility-options subMenu ${
                            visibility ? "active" : ""
                        }`}
                    >
                        <div
                            className={`everyone ${
                                visibilitySelection === "Everyone" ? "default" : ""
                            }`}
                            onClick={() => this.visibilityOptions("Everyone")}
                        >
                            Everyone
                        </div>
                        <div
                            className={`followers ${
                                visibilitySelection === "Followers Only"
                                    ? "default"
                                    : ""
                            }`}
                            onClick={() => this.visibilityOptions("Followers Only")}
                        >
                            Followers Only
                        </div>
                        <div
                            className={`circle ${
                                visibilitySelection === "Circle" ? "default" : ""
                            }`}
                            onClick={() => this.visibilityOptions("Circle")}
                        >
                            Circle
                        </div>
                        <div
                            className={`private ${
                                visibilitySelection === "Private" ? "default" : ""
                            }`}
                            onClick={() => this.visibilityOptions("Private")}
                        >
                            Private
                        </div>
                    </div>

                    <div className="spacer flex-5"></div>
                    <span
                        className="closeWindow flex-1"
                        onClick={() => this.closePost()}
                    >
                        <CloseIcon />
                    </span>
                    <div
                        type="text"
                        className="thePostContent flex-4"
                        contentEditable="true"
                        data-text="Whats up?"
                    ></div>
                    <div className="mediaUpload flex-4">
                        <MediaUploadIcon />
                    </div>
                    <div className="repliesToggle flex-1">
                        {" "}
                        <input type="checkbox"></input> Replies On
                    </div>
                    <div className="postButton flex-1">Post</div>
                </div>

                <div className={`screen ${newPost ? "active" : ""}`}></div>
                <header className="header">
                    <div className="logo">Asteroid</div>

                    <ul className="navigation">
                        <li>
                            <Link to="/feed">
                                <FeatherIcon />
                                Feed
                            </Link>
                        </li>
                        <li>
                            <Link to="/notifications">
                                <NotificationIcon />
                                Notifications
                            </Link>
                        </li>
                        <li>
                            <Link to="/communities">
                                <UsersIcon />
                                Communities
                            </Link>
                        </li>
                        <li>
                            <Link to="/messages">
                                <ChatnotificationIcon />
                                Messages
                            </Link>
                        </li>
                        <li>
                            <Link to="/user/posts">
                                <UsercircleIcon />
                                Profile
                            </Link>
                        </li>
                        <div
                            className="newpost button"
                            onClick={() => this.newPost()}
                        >
                            <AddcircleIcon /> New Post
                        </div>
                    </ul>
                </header>
            </>
        )
    }
}

export default Header
