import React from "react"
// import ReactDOM from "react-dom"
/*Images*/
import { ReactComponent as NotificationIcon } from "../images/notifcation.svg"
import { ReactComponent as OptionsIcon } from "../images/dots.svg"
// import { ReactComponent as ChattingIcon } from "../images/chatting.svg"
// import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
import { ReactComponent as UserIcon } from "../images/user.svg"
// import { ReactComponent as UsersIcon } from "../images/users.svg"
// import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
import { ReactComponent as StarIcon } from "../images/star.svg"
// import { ReactComponent as UploadIcon } from "../images/upload.svg"
import { ReactComponent as LinkIcon } from "../images/link.svg"
import { ReactComponent as LocationIcon } from "../images/location.svg"
// import { ReactComponent as FavouriteIcon } from "../images/Favourite.svg"
// import { ReactComponent as ExchangeIcon } from "../images/exchange.svg"
import { ReactComponent as ClockIcon } from "../images/clock.svg"

class Profile extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            followStatus: "Follow",
        }
        //onclick events can go here
    }

    follow = (followStatus) => {
        if (followStatus === "Follow") {
            this.setState({
                followStatus: "Following",
            })
        } else {
            this.setState({
                followStatus: "Follow",
            })
        }

        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        const { error, isLoaded, followStatus } = this.state
        console.log(this.state)

        return (
            <>
                <div className="search"></div>
                <div className="MainSideBar">
                    <div className="bio">
                        <div className="bio-top flex-1">
                            <div className="avatar flex-1">
                                <span className="bio-avatar-img"></span>
                            </div>
                            <div className="bio-account flex-3">
                                <div className="bio-name">
                                    Build'a'burrbles @ FC 2023
                                </div>
                                <div className="bio-at">@BurrblesBear</div>
                                <div className="account-actions">
                                    <div
                                        className="follow-button flex-3"
                                        onClick={() => this.follow(followStatus)}
                                    >
                                        {followStatus}
                                    </div>
                                    <div className="notifications flex-1">
                                        <NotificationIcon />
                                    </div>
                                    <div className="options flex-1">
                                        <OptionsIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bio-content ">
                            <span className="the-bio-content">
                                33 | NSFW | Dog Father | Destiny 2 | Photography |
                                Overlander | Chill Vibes & Warm Paws | Bear | Puppy |
                                Gator | ✂ <a href="#">@WaggeryCos</a> | AD:{" "}
                                <a href="#">@Underthenub</a>
                            </span>
                        </div>
                        <div className="follow-status flex-8">
                            <div className="followed flex-1">
                                <span className="count">3,034</span> <b>Followed</b>
                            </div>
                            <div class="following flex-7">
                                <span className="count">5,672</span> <b>Following</b>
                            </div>
                        </div>
                        <div className="bio-details ">
                            <div className="bio-deet flex-1 location">
                                <LocationIcon />
                                <span>Seattle</span>
                            </div>
                            <div className="bio-deet flex-1 link">
                                <LinkIcon />
                                <span>
                                    <a href="#">t.me/joinchat/UOoLX…</a>
                                </span>
                            </div>
                            <div className="bio-deet flex-1 pronouns">
                                <UserIcon />
                                <span>He/Him</span>
                            </div>
                            <div className="bio-deet flex-1 joined">
                                <ClockIcon />
                                <span>Joined Apirl 2008</span>
                            </div>
                            <div className="bio-deet flex-1 birthday">
                                <StarIcon />
                                <span>April 4th, 1989</span>
                            </div>
                        </div>
                    </div>
                    <div className="interest">
                        <h4 className="flex-4">Things I Post About</h4>
                        <div className="wordlist">
                            <span>Fursuits</span>
                            <span>4X4</span>
                            <span>Photography</span>
                            <span>Gaming</span>
                            <span>Politics</span>
                            <span>Music</span>
                            <span>FursuitFriday</span>
                        </div>
                    </div>
                    <div className="MyCommunities"></div>
                </div>
            </>
        )
    }
}

export default Profile
