import React from "react"
// import ReactDOM from "react-dom"
// /*Images*/
// import { ReactComponent as ChattingIcon } from "../images/chatting.svg"
// import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
// import { ReactComponent as UsersIcon } from "../images/users.svg"
// import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
// import { ReactComponent as StarIcon } from "../images/star.svg"
// import { ReactComponent as UploadIcon } from "../images/upload.svg"
// import { ReactComponent as LinkIcon } from "../images/link.svg"
// import { ReactComponent as LocationIcon } from "../images/location.svg"
// import { ReactComponent as FavouriteIcon } from "../images/Favourite.svg"
// import { ReactComponent as ExchangeIcon } from "../images/exchange.svg"
// import { ReactComponent as BookSearch } from "../images/book-search.svg"
// import { ReactComponent as OptionsIcon } from "../images/dots.svg"
// import tg1 from "../images/tg1.png"
// import tg2 from "../images/tg2.png"
// import tg3 from "../images/tg3.png"
// import tg4 from "../images/tg4.png"

class Post extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            postOptions: false,
            optionSelected: null,
            postID: null,
        }
        //onclick events can go here
    }

    options = (postID) => {
        this.setState({
            postOptions: true,
            postID: postID,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    optionSelected = (optionSelected) => {
        this.setState({
            postOptions: false,
            optionSelection: optionSelected,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        // const { error, isLoaded, postOptions, optionSelection, postID } = this.state
        // console.log(this.state);
        return (
            <>
                <article
                    className="communtiyContain"
                    data-community-id="48973498t478943734"
                >
                    <div className="avatar flex-1">
                        <span className="avatar-img"></span>
                    </div>
                    <div className="content-area flex-2">
                        <div className="details">
                            <div className="comName flex-0">
                                FurXFur PNW Overlanding Furs
                                <span className="lockIcon"></span>
                            </div>
                            <div className="communityStatus flex-8">
                                <div className="memberCount flex-1">
                                    <span className="count">3,034</span>{" "}
                                    <b>Members</b>
                                </div>
                                <div class="conversationCount flex-7">
                                    <span className="count">5,672</span>{" "}
                                    <b>Conversations</b>
                                </div>
                            </div>
                        </div>
                        <div className="the-content">
                            Group of pacific northwest furs that enjoy exploring the
                            world together. We're out normally year-round. Keep an
                            eye on pinned messages in the telegram chat for updates
                            and upcoming trips!
                        </div>
                    </div>
                </article>
            </>
        )
    }
}

export default Post
