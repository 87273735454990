import React from "react"
// import ReactDOM from "react-dom"
/*Images*/
// import { ReactComponent as ChattingIcon } from "../images/chatting.svg"
// import { ReactComponent as ChatnotificationIcon } from "../images/chat-notification.svg"
// import { ReactComponent as UsersIcon } from "../images/users.svg"
// import { ReactComponent as UsercircleIcon } from "../images/user-circle.svg"
// import { ReactComponent as StarIcon } from "../images/star.svg"
// import { ReactComponent as UploadIcon } from "../images/upload.svg"
// import { ReactComponent as LinkIcon } from "../images/link.svg"
// import { ReactComponent as LocationIcon } from "../images/location.svg"
// import { ReactComponent as FavouriteIcon } from "../images/Favourite.svg"
// import { ReactComponent as ExchangeIcon } from "../images/exchange.svg"
// import { ReactComponent as BookSearch } from "../images/book-search.svg"
// import { ReactComponent as OptionsIcon } from "../images/dots.svg"
import tg1 from "../images/tg1.png"
import tg2 from "../images/tg2.png"
import tg3 from "../images/tg3.png"
import tg4 from "../images/tg4.png"

class Post extends React.Component {
    constructor(props) {
        super(props)
        //setting intital state
        this.state = {
            error: null,
            isLoaded: false,
            postOptions: false,
            optionSelected: null,
            postID: null,
        }
        //onclick events can go here
    }

    options = (postID) => {
        this.setState({
            postOptions: true,
            postID: postID,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }
    optionSelected = (optionSelected) => {
        this.setState({
            postOptions: false,
            optionSelection: optionSelected,
        })
        // console.log(this.state);
        // this.forceUpdate();
    }

    render() {
        // const { error, isLoaded, postOptions, optionSelection, postID } = this.state
        console.log(this.state)
        return (
            <>
                <div className="userGallery">
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                    <div className="galleryItem flex-1">
                        <img src={tg1} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg2} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg3} />
                    </div>
                    <div className="galleryItem">
                        <img src={tg4} />
                    </div>
                </div>
            </>
        )
    }
}

export default Post
